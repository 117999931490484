import { toast } from 'react-toastify';
import { post, get } from './request';
import { createEnrollData } from 'helpers/parse';
const order = {
  create: (data) => {
    return post('/api/createEnrollOrder', data).then(
      (orderInfo) => orderInfo?.[0]?.id,
    );
  },
  createItems: (items, orderId, country) => {
    const products = items.map((item) => ({ ...item, orderId, country }));
    return post('/api/orderItems', { products }, {});
  },
  updateTotals: (orderRow) => {
    return post('/api/orderUpdateTotalsEnrollment', {
      orderRow,
    });
  },
  updateOrderAndRepId: (orderInfo) => {
    return post('/api/updateOrderAndRepId', orderInfo);
  },
  getDetails: (id) => {
    return get(`/api/getOrderDetails/:${id}`);
  },
  getGtmInfo: ({ orderId }) => {
    return get(`/api/getGtmInfoForOrders/:${orderId}`);
  },
  guid: (data) => {
    return post('/api/createGuidForPaymentiFrame', data);
  },
  createAll: async (enrollData) => {
    const data = createEnrollData(enrollData);
    const { errors, userId, orderId } = await post('/api/createUserComplete', data)
    if (!userId || !orderId) throw Object.assign(new Error('Unable To Create Order'), { errors });
    if (errors.length > 0) throw Object.assign(new Error('Error saving account'), { errors });
    return { userId, orderId };
  },
  evaluatePromotions: (OrderRow, LocaleId) => {
    return post('/api/evaluatePromotions', {
      OrderRow,
      LocaleId,
    }).then(({ PromotionAwarded = [], PromotionSuggested = [] } = {}) => {
      try {
        const promotions = [...PromotionAwarded, ...PromotionSuggested];
        // Gets all promotional items that are added for a fixed price.
        return promotions.reduce(
          (prev, curr) =>
            curr.PromotionActionTypeId === 7
              ? [
                  ...prev,
                  ...curr.Items.map((item) => ({
                    ...item,
                    TotalQuantity: curr.TotalQuantity,
                  })),
                ]
              : prev,
          [],
        );
      } catch (e) {
        return [];
      }
    }).catch(err => {
      toast.error(err, { toastId: err })
    });
  },
};

export default order;
