import { useEffect, useState } from 'react';
import { getEntryByLocale } from 'api/contentful';
import { useRecoilValue } from 'recoil';
import { formState } from 'state';

export default function useContentfulApi(entryId) {
  const { locale } = useRecoilValue(formState);
  const [data, setData] = useState();

  useEffect(() => {
    if (entryId) {
      getEntryByLocale(entryId, locale).then((res) => {
        const { error } = res;
        !error && setData(res);
      });
    }
  }, [locale, entryId]);

  return data;
}
