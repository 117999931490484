import { createContext, useContext, useEffect, useState } from 'react';

const NextContext = createContext();

export default NextContext;

export function NextContextProvider({ children }) {
  const defaultError = {
    enabled: false,
    message: 'Oops! Something went wrong on our end. Please try again.',
    code: 500,
  };
  const defaultTip = {
    enabled: false,
    message: 'Please complete the following items to continue.',
  };
  const [next, setNext] = useState({
    enabled: false,
    skip: false,
    validate: async () => true,
  });
  const [error, setError] = useState(defaultError);
  const [tip, setTip] = useState(defaultTip);

  useEffect(() => {
    if (error.enabled) autoClose();
  }, [error.enabled]);

  const autoClose = () => {
    setTimeout(() => {
      setError(defaultError);
    }, 3000);
  };

  const enableSkip = () => {
    setNext({ ...next, enabled: true, skip: true });
  };

  const replaceSkip = () => {
    setNext({ ...next, enabled: true, skip: false });
  };
  const setErrorMessage = (
    message = defaultError.message,
    code = defaultError.code,
  ) => {
    setError({ ...error, enabled: true, message, code });
  };
  const setTipMessage = (message = defaultTip.message) => {
    setTip({ enabled: true, message });
  };

  return (
    <NextContext.Provider
      value={{
        next,
        error,
        tip,
        setNext,
        setError,
        setTip,
        enableSkip,
        replaceSkip,
        setErrorMessage,
        setTipMessage,
      }}
    >
      {children}
    </NextContext.Provider>
  );
}

export function useNextContextConsumer() {
  const context = useContext(NextContext);

  if (context === undefined) {
    throw new Error(
      'useNextContextConsumer must be used within a NextContextProvider',
    );
  }

  return context;
}
