import axios from 'axios';
import config from 'config';
import { toast } from 'react-toastify';

const dateString = new Date().toISOString()
const setHeaders = () => {
  const headers = {}
  // Set session tracking header
  const persistedDateString = localStorage.getItem('NUL_DATE_STRING')
  if (!persistedDateString) localStorage.setItem('NUL_DATE_STRING', dateString)
  headers['x-session-start'] = dateString
  headers['x-session-start-persisted'] = persistedDateString || dateString
  // Set error simulation headers
  let params = new URL(document.location).searchParams;
  if (params.get('nul-simulate-error')) headers['x-nul-simulate-error'] = params.get('nul-simulate-error')
  return {
    baseURL: config.REACT_APP_API_URL,
    headers
  };
}

// request error handler
const request = (args) => {
  return axios({ ...args, ...setHeaders() })
    .then((response) => {
      return handleBackendResponse(response);
    })
    .catch(handleError);
};

// GET
export const get = (url) => request({ url, method: 'get' });

// POST
export const post = (url, data, config) =>
  request({ ...config, url, data, method: 'post' });

export function handleError(error) {
  const newError = parseError(error);
  debugLog(newError);
  toast.error(`${newError.message} at ${newError.url}${newError.id ? ` - ${newError.id}` : ''}`, {
    toastId: newError.url,
  })
  return Promise.reject(newError);
}

function debugLog(message) {
  if (process.env.NODE_ENV !== 'production') console.debug(message);
}
function parseError(error) {
  const { config: { url, baseURL, data } = {}, message, id } = error; // axios call error properties
  return {
    id,
    url,
    baseURL,
    data,
    message,
    ...error,
  };
}
function handleBackendResponse(response) {
  // different type of backend errors
  const error = !!parseError(response)?.message;
  if (error) return handleError(response);
  return Promise.resolve(parseBackEndData(response));
}

function parseBackEndData(response) {
  const { data } = response;
  function returnAndLog(data) {
    debugLog({
      url: response.config.url,
      data,
    });
    return data;
  }
  // if a recordset array is returned
  if (data?.recordset) return returnAndLog(data.recordset);
  return returnAndLog(data);
}

export function clientRequest(url, data, config) {
  // for clientside errors we dont need to show anything to the user so undefined is fine and easy to check
  return {
    get: () => get(url).catch(() => Promise.resolve()),
    post: () => post(url, data, config).catch(() => Promise.resolve()),
  };
}
