import { useEffect, useState, useCallback, useMemo } from 'react';
import { toast } from 'react-toastify';
import { ReactComponent as DropdownSVG } from 'assets/svg/dropdown.svg';
import getLanguages from 'api/getLanguages';
import { useNextContextConsumer } from 'contexts/NextContext';
import { useRecoilState, useRecoilValueLoadable } from 'recoil';
import { formState, marketsState, countriesState } from 'state';

import { Stack, Box, Typography, Menu, MenuItem } from '@newulife/common'

export default function LanguageSelector() {
  // Context
  const [form, setForm] = useRecoilState(formState);
  const { locale, businessMarket: { iso2: marketIso } = {} } = form;
  const { setErrorMessage } = useNextContextConsumer();
  const { state: marketsQueryState, contents: markets } = useRecoilValueLoadable(marketsState)
  const { state: countriesQueryState, contents: countries } = useRecoilValueLoadable(countriesState)
  const [languages, setLanguages] = useState([])
  const [currentLanguage, setCurrentLanguage] = useState({})
  const [anchorEl, setAnchorEl] = useState(null)

  const filteredLanguages = useMemo(() => {
    return languages?.filter(o => o.name !== currentLanguage.name)
  }, [currentLanguage, languages])

  const hasOtherLanguages = filteredLanguages?.length > 0

  useEffect(() => {
    // TODO: being called twice - move to recoil
    getLanguages(marketIso).then((languages) => {
      if (languages.error) {
        setErrorMessage(languages.error);
        toast.error(languages.error, { toastId: languages.error })
      } else {
        setLanguages(languages)
      }
    })
  }, [])

  useEffect(() => {
    setLanguage(locale)
    setmarketAndCountryLabels(locale)
  }, [languages, locale])

  const setLanguage = useCallback(
    (locale) => {
      if (languages.length !== 0 && currentLanguage?.locale !== locale) {
        const language = languages?.find(
          (language) => language?.locale === locale,
        )
        if (language) setCurrentLanguage(language)
      }
    },
    [locale, languages],
  )

  const setmarketAndCountryLabels = useCallback(
    (locale) => {
      if (marketsQueryState !== 'hasValue' || countriesQueryState !== 'hasValue')
        return;
      const {
        businessMarket: { iso2: market } = {},
        countryInfo: { iso2: country } = {},
      } = form;
      const currentMarket = markets?.find(({ iso2 }) => iso2 === market);
      const currentCountry = countries?.find(({ iso2 }) => iso2 === country);
      setForm({
        ...form,
        businessMarket: currentMarket,
        countryInfo: currentCountry,
      });
    }, [locale],
  );

  const handleChange = (e, language) => {
    e.preventDefault()
    setCurrentLanguage(language)
    setForm({ ...form, locale: language.locale, localeId: language.id })
    setAnchorEl(null)
  }

  const handleOpen = e => {
    setAnchorEl(e.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  // Render language selector after languages have been retrieved from db.
  if (languages.length === 0) return null

  return (
    <div>
      <Stack
        data-testid="select-right-submenu"
        display="inline-grid"
        gap={1}
        gridAutoFlow="column"
        justifyContent="flex-start"
        alignItems="center"
        alignContent="center"
        htmlFor="lang-menu"
        aria-label="Language Selection"
        aria-haspopup="true"
        sx={{
          cursor: hasOtherLanguages ? 'pointer' : undefined,
        }}
        onClick={handleOpen}
      >
        <Typography
          variant="button"
          data-testid="langMenuValue"
          onClick={e => e.preventDefault()}
        >
          {currentLanguage?.name}
        </Typography>
        <Box
          display="inline-flex"
          sx={theme => ({
            transform: Boolean(anchorEl) ? 'rotate(-180deg)' : '',
            transition: theme.transitions.create(['transform']),
          })}
        >
          <DropdownSVG />
        </Box>
      </Stack>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          marginTop: '10px',
        }}
      >
        {filteredLanguages.map(o => (
          <MenuItem
            data-testid={`submenu-selection_${o.name}`}
            key={o.name}
            value={o.name}
            onClick={e => handleChange(e, o)}
          >
            {o.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}
