import { useEffect, useState } from 'react';
import { isEmpty, isNull } from 'lodash';
import order from 'api/order';
import shipping from 'api/shipping';
import subscription from 'api/subscription';
import { useLoader } from 'contexts/LoaderContext';
import { useNextContextConsumer } from 'contexts/NextContext';
import { useProducts } from 'contexts/ProductContext';
import { useSubscriptions } from 'contexts/SubscriptionContext';
import ProductOrder from './ProductOrder';
import SelectShippingMethod from './SelectShippingMethod';
import SubscriptionOrder from './SubscriptionOrder';
import { parseRichText } from 'helpers/contentful';
import { Typography } from '@newulife/common'
import { useRecoilState, useRecoilValue } from 'recoil';
import { formState } from 'state';
import { toast } from 'react-toastify';

export default function ReviewYourOrder({
  data,
  productData,
  subscriptionData,
  disclaimer,
}) {
  // Context
  const [form, setForm] = useRecoilState(formState);
  const {
    authRep,
    locale,
    countryInfo: { name: shippingCountry },
    orderId,
    userId,
    localeId,
    shipMethodId,
  } = form;
  const { showLoader, hideLoader } = useLoader();
  const {
    next,
    setNext,
    tip,
    setTip,
    error: appErrorObject,
    setError,
  } = useNextContextConsumer();
  const { parseProductsForOrder } = useProducts();
  const { parseSubscriptionsForOrder } = useSubscriptions();
  // State
  const [methods, setMethods] = useState([]);
  const [details, setDetails] = useState({
    OrderTotal: 0,
    ShippingTotal: 0,
    TaxTotal: 0,
  });
  const [promos, setPromoItems] = useState([]);
  const [hasPromotions, setHasPromotions] = useState(false);
  const [hasTotals, setHasTotals] = useState(false);

  // Enables next button when shipping has been selected.
  useEffect(() => {
    setNext({ ...next, enabled: !isNull(shipMethodId), validate });
  }, [shipMethodId]);

  // Review step api calls.
  useEffect(() => {
    showLoader();
    evaluatePromotions();
    getShippingMethods();
    setTip({ ...tip, message: 'Please select a shipping method.' });
    hideLoader();
  }, []);

  //custom setError
  function showCustomError(string) {
    if (typeof string === 'string')
      setError({ ...appErrorObject, enabled: true, message: string });
  }

  // Get shipping methods for the order.
  const getShippingMethods = () => {
    // Get shipping methods for the order.
    shipping
      .get({
        orderId,
        shippingCountry,
        locale,
      })
      .then((shippingMethods) => {
        setMethods(shippingMethods);
        updateShippingMethod(shipMethodId);
        const onlyOneShipMethod = shippingMethods?.length === 1;
        if (!shipMethodId && onlyOneShipMethod) {
          setFormShipMethod(shippingMethods[0].ID);
        }
      })
      .catch(() => {
        showCustomError('Server problem please try again');
      })
      .finally(() => {
        hideLoader();
      });
  };

  // Validate if the method has already been selected.
  const setFormShipMethod = (id) => {
    if (shipMethodId !== id) {
      updateShippingMethod(id).then((success) => {
        if (success?.error) showCustomError(success.error.message);
        else setForm({ ...form, shipMethodId: id });
      });
    }
  };

  // Apply promotions to the current order.
  const evaluatePromotions = async () => {
    order.evaluatePromotions(orderId, localeId).then((items) => {
      if (items) {
        setPromoItems(items);
      }
      setHasPromotions(!!items);
    });
  };

  // Update shipping method on order.
  const updateShippingMethod = (shipMethodId) => {
    if (!shipMethodId) return Promise.resolve(false);
    setHasTotals(false);
    showLoader();
    const data = { rowNumber: orderId, shipMethodId };

    // Update shipping method on order to receive totals.
    return shipping
      .updateShippingMethod(data)
      .then((details) => {
        if (!details?.error) {
          setDetails({ ...details });
          setHasTotals(true);
        }
      })
      .catch(err => {
        toast.error('Could not update shipping method. If problem persists, please contact support', {
          toastId: "update-shipping-method"
        })
      })
      .finally(() => {
        hideLoader();
      });
  };

  // Validate before heading to payment page.
  const validate = async () => {
    if (orderId && userId) {
      try {
        const { origin } = window.location;

        // Tag subscription items for autoship from review to payment.
        await subscription.addToAutoship(orderId);

        // Update totals for our own DB.
        await order.updateTotals(orderId);

        // Create guid for payment page.
        const guid = await order.guid({
          OnlineOrderID: orderId,
          OnlineSignupID: userId,
          ReturnURL: `${origin}/steps/confirmation`,
          CancelURL: `${origin}/steps/review`,
          AuthenticatedRepDID: !isEmpty(authRep) ? authRep.RepDID : '',
        });
        return { guid };
      } catch(err) {
        toast.error(
          typeof err === 'string' 
            ? err 
            : err?.error || 'Order could not be validated. If the problem persists, please contact support.',
          { toastId: 'validate' }
        )
        return false;
      }
    }
  };

  // Update the order.
  const update = async () => {
    const products = parseProductsForOrder({ isUpdate: true });
    const subscriptions = parseSubscriptionsForOrder({ isUpdate: true });
    const items = [...products, ...subscriptions];

    showLoader();
    await order.createItems(items, orderId, shippingCountry);
    await evaluatePromotions();
    getShippingMethods();
  };

  if (methods.length === 0) {
    return null;
  }

  const { title } = data.fields;

  return (
    <>
      <Header title={title} />
      <SelectShippingMethod
        data={data}
        methods={methods}
        validateMethod={setFormShipMethod}
      />
      <ProductOrder
        data={data}
        modalData={productData}
        details={details}
        promos={promos}
        hasPromotions={hasPromotions}
        hasTotals={hasTotals}
        update={update}
      />
      <SubscriptionOrder
        data={data}
        modalData={subscriptionData}
        update={update}
      />
      <Disclaimer data={disclaimer} />
    </>
  );
}

const Header = ({ title }) => {
  return (
    <section className="intro-text center">
      <Typography
        variant="header1"
      >
        {title}
      </Typography>
    </section>
  );
};

const Disclaimer = (props) => {
  const { businessMarket: { englishName: country } } = useRecoilValue(formState);
  const { listArray } = props?.data?.fields;
  if (!listArray?.length) return null;

  const item = listArray.find(({ fields: { descriptiveTitle: title } }) =>
    title.includes(country),
  );

  const richText = item?.fields?.text1 || listArray?.[0]?.fields?.text1;
  return (
    <section className="center">
      <span className="text-center wide-desktop">
        {parseRichText(richText)}
      </span>
    </section>
  );
};
